import React from "react";

import * as Hooks from "../../lib/Hooks";
import "./style.scss";

const SlideImage = ({ images, imagesMobile, ...faderProps }) => {
  const imageSet = faderProps.isMobile && imagesMobile.length > 0 ? imagesMobile : images;
  const slider = Hooks.useScrollTrigger({
    className: "SlideImage",
    ...faderProps
  })
  return (
    <div {...slider}>
      {imageSet.map((img, ix) => <img src={img.url} alt={img.description} key={ix}/>)}
    </div>
  );
}

export default SlideImage;
