import * as Contentful from "contentful"

const client = Contentful.createClient({
  space: "5povsx9ui80c",
  accessToken: "zuntxuHjh4M23cQ6FvgEVV4aecTUkS8-sXd7d8WBODo"
});

// ----------------------------------------------------------------------------

export const newsCache = { value: [] };

export const getNews = async () => {
  if (newsCache.value.length > 0) return newsCache.value;
  const entries = (await client.getEntries({ content_type: "news", order: "-fields.date" })).items.map(item => {
    const entry = item.fields;
    return Object.assign({ image: findFirstImage(item.fields.content) }, entry);
  });
  newsCache.value = entries;
  return entries;
};

export const getNewsItem = async (slug) =>
  (await getNews()).find(item => item.slug === slug);

//
// Attempts to synchronously find a news item from the cache. This will always
// fail to return anything if the news listing has not yet been loaded, but in
// the case when transitioning from the news listing page to a news article it
// will succeed and prevent the content flickering when the page switchover
// happens.
//
export const getCachedNewsItem = (slug) =>
  newsCache.value.find(item => item.slug === slug);

//
// Find the first image present in a rich text area. Used to pull an image out
// the content for news items. Returns `undefined` if no image is found.
//
const findFirstImage = (doc) => {
  if (doc.nodeType === "document" && !!doc.content) {
    for (var i = 0; i < doc.content.length; i++) {
      const node = doc.content[i];
      if (node.nodeType === "embedded-asset-block") {
        const file = node.data.target.fields.file;
        if (file.contentType.startsWith("image/")) {
          return { url: file.url, description: node.data.target.fields.description };
        }
      }
    }
  }
};

// ----------------------------------------------------------------------------

const imageCache = {};

//
// Loads an image and computs a data URL for it. Used to cache images when
// transitioning from the news listing page to a news article in an attempt
// to reduce flickering when the page switchover happens.
//
export const cacheImage = (url) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    imageCache[url] = canvas.toDataURL();
  }
  img.src = url;
}

//
// Attempts to fetch the cached URL for an image. If the image is missing from
// the cache the original URL will be returned instead
//
export const getCachedImage = (url) =>
  imageCache[url] || url;

// ----------------------------------------------------------------------------

export const caseStudiesCache = { value: [] };

export const getCaseStudies = async () => {
  if (caseStudiesCache.value.length > 0) return caseStudiesCache.value;
  const listing = (await(client.getEntries({ content_type: "caseStudyListing", include: 3 }))).items[0];
  const entries = listing.fields.caseStudies.map(item => item.fields);
  caseStudiesCache.value = entries;
  return entries;
};

export const getCaseStudy = async (slug) =>
  (await getCaseStudies()).find(item => item.slug === slug);

// ----------------------------------------------------------------------------

export const jobsCache = { value: [] };

export const getJobs = async () => {
  if (jobsCache.value.length > 0) return jobsCache.value;
  const entries = (await client.getEntries({ content_type: "job" })).items.map(item => item.fields);
  jobsCache.value = entries;
  return entries;
};
