import React from "react";

import "./style.scss"

export const Privacy = () =>
  <div className="Policy">
    <h1>Privacy policy</h1>
    <p>February 05, 2019</p>
    <p>Magnetic North Interactive Limited ("us", "we", or "our") operates the <a href="https://thisismn.com">https://thisismn.com</a> website (hereinafter referred to as the "Service").</p>
    <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a href="https://thisismn.com">https://thisismn.com</a></p>
    <h3>Definitions</h3>
    <ul><li>Service</li></ul><p>Service is the <a href="https://thisismn.com">https://thisismn.com</a> website operated by Magnetic North Interactive Limited</p>
    <ul><li>Personal Data</li></ul><p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
    <ul><li>Usage Data</li></ul><p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
    <ul><li>Cookies</li></ul><p>Cookies are small files stored on your device (computer or mobile device).</p>
    <ul><li>Data Controller</li></ul><p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
    <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
    <ul><li>Data Processors (or Service Providers)</li></ul><p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>
    <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
    <ul><li>Data Subject (or User)</li></ul><p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>
    <h3>Information Collection and Use</h3>
    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
    <h3>Types of Data Collected</h3>
    <p>Personal Data</p>
    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to</p>
    <ul><li>Email address</li><li>First name and last name</li><li>Phone number</li><li>Address, State, Province, ZIP/Postal code, City</li><li>Cookies and Usage Data</li></ul><p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or the instructions provided in any email we send.</p>
    <p>Usage Data</p>
    <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
    <p>Tracking &amp; Cookies Data</p>
    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p>
    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
    <p>Examples of Cookies we use</p>
    <ul><li>Session Cookies. We use Session Cookies to operate our Service.</li><li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li><li>Security Cookies. We use Security Cookies for security purposes.</li></ul><h3>Use of Data</h3>
    <p>Magnetic North Interactive Limited uses the collected data for various purposes</p>
    <ul><li>To provide and maintain our Service</li><li>To notify you about changes to our Service</li><li>To allow you to participate in interactive features of our Service when you choose to do so</li><li>To provide customer support</li><li>To gather analysis or valuable information so that we can improve our Service</li><li>To monitor the usage of our Service</li><li>To detect, prevent and address technical issues</li><li>To provide you with news, special offers and general information
    about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li></ul><h3>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h3>
    <p>If you are from the European Economic Area (EEA), Magnetic North Interactive Limited legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
    <p>Magnetic North Interactive Limited may process your Personal Data because</p>
    <ul><li>We need to perform a contract with you</li><li>You have given us permission to do so</li><li>The processing is in our legitimate interests and it is not overridden by your rights</li><li>To comply with the law</li></ul><h3>Retention of Data</h3>
    <p>Magnetic North Interactive Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
    <p>Magnetic North Interactive Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</p>
    <h3>Transfer of Data</h3>
    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
    <p>If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Kingdom and process it there.</p>
    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
    <p>Magnetic North Interactive Limited will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
    <h3>Disclosure of Data</h3>
    <h3>Business Transaction</h3>
    <p>If Magnetic North Interactive Limited is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
    <h3>Disclosure for Law Enforcement</h3>
    <p>Under certain circumstances, Magnetic North Interactive Limited may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
    <h3>Legal Requirements</h3>
    <p>Magnetic North Interactive Limited may disclose your Personal Data in the good faith belief that such action is necessary to</p>
    <ul><li>To comply with a legal obligation</li><li>To protect and defend the rights or property of Magnetic North Interactive Limited</li><li>To prevent or investigate possible wrongdoing in connection with the Service</li><li>To protect the personal safety of users of the Service or the public</li><li>To protect against legal liability</li></ul><h3>Security of Data</h3>
    <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
    <h3>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h3>
    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Magnetic North Interactive Limited aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</p>
    <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
    <p>In certain circumstances, you have the following data protection rights</p>
    <ul><li><p>The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</p>
    </li><li><p>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
    </li><li><p>The right to object. You have the right to object to our processing of your Personal Data.</p>
    </li><li><p>The right of restriction. You have the right to request that we restrict the processing of your personal information.</p>
    </li><li><p>The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</p>
    </li><li><p>The right to withdraw consent. You also have the right to withdraw your consent at any time where Magnetic North Interactive Limited relied on your consent to process your personal information.</p>
    </li></ul><p>Please note that we may ask you to verify your identity before responding to such requests.</p>
    <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
    <h3>Service Providers</h3>
    <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
    <h3>Analytics</h3>
    <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
    <ul><li>Google Analytics</li></ul><p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
    <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>
    <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page <a href="https://web.archive.org/web/20200920192100/https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
    <h3>Links to Other Sites</h3>
    <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
    <h3>Children's Privacy</h3>
    <p>Our Service does not address anyone under the age of 18 ("Children").</p>
    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
    <h3>Changes to This Privacy Policy</h3>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    <h3>Contact Us</h3>
    <p>If you have any questions about this Privacy Policy, please contact us</p>
    <ul><li>By email <a href="mailto:hello@thisismn.com">hello@thisismn.com</a></li></ul>
  </div>

export const Cookies = () =>
  <div className="Policy">
    <h1>Cookie Policy</h1>
    <p>This is the Cookie Policy for <a href="https://thisismn.com">https://thisismn.com</a>, accessible from <a href="https://thisismn.com/cookie-policy">https://thisismn.com/cookie-policy</a></p>
    <h3>What Are Cookies</h3>
    <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>
    <p>For more general information on cookies see <a href="https://en.wikipedia.org/wiki/HTTP_cookie">the Wikipedia article on HTTP Cookies</a>.</p>
    <h3>How We Use Cookies</h3>
    <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
    <h3>Disabling Cookies</h3>
    <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
    <h3>The Cookies We Set </h3>
    <p>Site preferences cookies</p>
    <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
    <p>Third Party Cookies</p>
    <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
    <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
    <p>For more information on Google Analytics cookies, see the official Google Analytics page. </p>
    <h3>More Information</h3>
    <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>
    <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods</p>
    <ul><li>Email <a href="mailto:hello@thisismn.com">hello@thisismn.com</a></li></ul>
  </div>
