import React from "react"

import classNames from "classnames"

import "./style.scss"

const Fader = React.forwardRef(({ children, visible }, ref) =>
  <div className={classNames({ "FadeContainer": true, "FadeContainer--visible": visible })} ref={ref}>
    {children}
  </div>
);

export default Fader;
