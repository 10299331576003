import { Link } from "@reach/router"

import "./style.scss";
import imgTwitter from "./twitter.svg";
import imgLinkedin from "./linkedin.svg";

const Footer = (props) =>
  <div className={props.solid ? "Footer Footer--solid" : "Footer"}>
    <div className="Footer-container">
      <div className="Footer-contact">
        <div>Get in touch:</div>
        <ul className="Footer-contactLinks">
          <li><a href="mailto:hello@thisismn.com">hello@thisismn.com</a></li>
        </ul>
      </div>
      <div className="Footer-careers">
        <div>Interested in joining the team?</div>
        <a href="mailto:careers@thisismn.com">careers@thisismn.com</a>
      </div>
      <div className="Footer-findUs">
        Find us:
        <ul className="Footer-address">
          <li>No. 2 Circle Square</li>
          <li>1 Symphony Park</li>
          <li>Manchester</li>
          <li>M1 7FS</li>
        </ul>
      </div>
      <div className="Footer-social">
        <ul className="Footer-socialLinks">
          <li><a href="https://twitter.com/thisismn_"><img src={imgTwitter} alt="Twitter"/></a></li>
          <li><a href="https://www.linkedin.com/company/magneticnorth"><img src={imgLinkedin} alt="LinkedIn"/></a></li>
        </ul>
      </div>
    </div>
    <div className="Footer-policies">
      View our <Link to="/privacy-policy">privacy policy</Link>. <br/>This site uses cookies <Link to="/cookie-policy">view our cookie policy</Link>.
    </div>
  </div>;

export default Footer;
