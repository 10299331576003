import React from "react";
import classNames from "classnames";

import BlockText from "../../element/BlockText"
import Footer from "../../element/Footer"
import ScrollFader from "../../element/ScrollFader"
import SlideImage from "../../element/SlideImage"

import * as Lib from "../../lib"
import * as API from "../../lib/api"
import * as Hooks from "../../lib/Hooks"

import "./style.scss"
import imgHeader from "./header.png"
import imgScroll from "../../img/scroll-black.svg"
import imgCulture1 from "./culture1.svg"
import imgCulture2 from "./culture2.svg"
import imgCulture1Mobile from "./culture1-mobile.svg"
import imgCulture2Mobile from "./culture2-mobile.svg"
import imgCulture3Mobile from "./culture3-mobile.svg"
import imgOfficeLeft from "./office-left.jpg"
import imgOfficeRight from "./office-right.jpg"
import imgVacancies from "./vacancies.svg"

const Vacancy = ({ screenArea, pageHeight, title, shortDescription, fullDescription }) => {
  const [isOpen, updateIsOpen] = React.useState(false);
  const contentBounds = Hooks.useBounds({ screenArea, pageHeight });
  const contentClasses = classNames({
    "CareersPage-vacancyContent": true,
    "CareersPage-vacancyContent-visible": isOpen
  });
  return (
    <li className="CareersPage-vacancy">
      <div className="CareersPage-vacancyTitle">{title.toUpperCase()}</div>
      <div className="CareersPage-vacancySnippet">{shortDescription}</div>
      <div className={contentClasses} style={{ height: isOpen ? contentBounds.rect.height : 0 }}>
        <div className="CareersPage-vacancyContentInner" ref={contentBounds.ref}>
          {Lib.renderRichText(fullDescription)}
        </div>
      </div>
      <button className="CareersPage-vacancyCTA" onClick={() => updateIsOpen(!isOpen)}>
        {isOpen ? "See less" : "See more"}
      </button>
    </li>
  );
};

const slideImages = [
  { url: imgCulture1, description: "We believe 3 things" },
  { url: imgCulture2, description: "make a happy culture:" }
];

const slideImagesMobile = [
  { url: imgCulture1Mobile, description: "We believe 3" },
  { url: imgCulture2Mobile, description: "things make a" },
  { url: imgCulture3Mobile, description: "happy culture:" }
];

const Careers = ({ screenArea, pageHeight, scrollPos, scaling, contentWidth, contentMargin, pageMargin, isMobile }) => {

  const faderProps = { screenArea, pageHeight, scrollPos, isMobile };
  const headerBounds = Hooks.useBounds({ screenArea, pageHeight });
  const [jobs, updateJobs] = React.useState([]);

  React.useEffect(() => {
    API.getJobs()
      .then(updateJobs)
      .catch(err => console.log(err));
  }, []);

  const backgroundOpacity =
    headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * 0.65)
      ? 1
      : 0;

  const heroImageClass = classNames({
    "Hero-image": true,
    "Hero-image-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * (isMobile ? 0.8 : 0.7))
  });

  const heroTitleClass = classNames({
    "Hero-title": true,
    "Hero-title-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * (isMobile ? 0.7 : 0.8))
  });

  const heroScrollClass = classNames({
    "Hero-scroll": true,
    "Hero-scroll-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * 0.85)
  });

  const triggerPoint = isMobile ? 0.85 : 0.7;

  const p1Trigger = Hooks.useScrollTrigger({
    className: "CareersPage-cultureItem",
    triggerPoint,
    ...faderProps
  });

  const p2Trigger = Hooks.useScrollTrigger({
    className: "CareersPage-cultureItem",
    triggerPoint,
    ...faderProps
  });

  const p3Trigger = Hooks.useScrollTrigger({
    className: "CareersPage-cultureItem",
    triggerPoint,
    ...faderProps
  });

  const valuesTrigger = Hooks.useScrollTrigger({
    className: "CareersPage-values",
    triggerPoint: isMobile ? 1.15 : 0.6,
    ...faderProps
  });

  const vacanciesTrigger = Hooks.useScrollTrigger({
    className: "CareersPage-vacanciesImage",
    triggerPoint: isMobile ? 0.9 : 0.7,
    ...faderProps
  });

  const jobsSplit = Math.ceil(jobs.length / 2);

  return <div className="Page">

    <div className="AboutPage-background" style={{opacity: backgroundOpacity}}/>

    <div className="Hero" ref={headerBounds.ref}>
      <div className="Hero-content">
        <div className={heroImageClass}>
          <img src={imgHeader} className="Hero-img Hero-img-careers" alt=""/>
        </div>
        <div className={heroTitleClass}>
          <BlockText>CULTURE &</BlockText>
          <BlockText>CAREERS</BlockText>
          <BlockText>AT mN</BlockText>
        </div>
        <img src={imgScroll} className={heroScrollClass} alt="Scroll down"/>
      </div>
    </div>

    <div className="CareersPage">

      <SlideImage
        images={slideImages}
        imagesMobile={slideImagesMobile}
        triggerPoint={0.8}
        {...faderProps}/>

      <div className="CareersPage-culture">
        <ul className="CareersPage-cultureList">
          <li {...p1Trigger}>
            <div className="CareersPage-cultureHeading">AUTONOMY</div>
            <hr className="CareersPage-underline CareersPage-underline-1"/>
            <div className="CareersPage-culturePoint">Giving everyone the opportunity to make decisions, and mistakes!</div>
          </li>
          <li {...p2Trigger}>
            <div className="CareersPage-cultureHeading">PROGRESS</div>
            <hr className="CareersPage-underline CareersPage-underline-2"/>
            <div className="CareersPage-culturePoint">Helping everyone to master their craft and move forward at their own pace.</div>
          </li>
          <li {...p3Trigger}>
            <div className="CareersPage-cultureHeading">TOGETHERNESS</div>
            <hr className="CareersPage-underline CareersPage-underline-3"/>
            <div className="CareersPage-culturePoint">Working with people you enjoy spending time with is really important.</div>
          </li>
        </ul>

        <div className="CareersPage-principles">
          <ScrollFader {...faderProps} fadePoint={0.8}>
          These principles sit at the heart of how we work together and how we measure our success.
          </ScrollFader>
        </div>
      </div>

      <div className="CareersPage-photos">
        <img src={imgOfficeLeft} alt=""/>
        <img src={imgOfficeRight} alt=""/>
      </div>

      <div {...valuesTrigger}>
        <div className="CareersPage-valuesLeft">
          When we invite new people to join our team we look first at who shares our values and ambitions, long before we have any conversations about skills and capabilities.
        </div>
        <div className="CareersPage-valuesRight">
          We work in multidisciplinary teams in which peoples’ job titles point to their specialist skills but don’t restrict the contribution they make.
        </div>
      </div>

      <div className="CareersPage-vacanciesSlider">
        <img src={imgVacancies} alt="Vacancies" {...vacanciesTrigger}/>
      </div>

      <div className="CareersPage-vacanciesBlock">
        <ul className="CareersPage-vacanciesList">
          {jobs.slice(0, jobsSplit).map((data, ix) => <Vacancy key={data.title} screenArea={screenArea} pageHeight={pageHeight} {...data}/>)}
        </ul>
        <ul className="CareersPage-vacanciesList">
          {jobs.slice(jobsSplit).map((data, ix) => <Vacancy key={data.title} screenArea={screenArea} pageHeight={pageHeight} {...data}/>)}
        </ul>
      </div>

    </div>

    <Footer solid/>

  </div>
}

export default Careers;
