import React from "react";
import { Link } from "@reach/router"
import classNames from "classnames";

import BlockText from "../../element/BlockText"

import "./style.scss"
import imgArrow from "./arrow.svg"

const messages = [
  { scribble: "Ideas.", blocks: ["WE mAKE", "THEM HAPPEN"] },
  { scribble: "We're a", blocks: ["DIGITAL", "DESIGN STUDIO"] },
  { scribble: "This is", blocks: ["HOW WE GET", "THINGS DONE"], url: "/work" }
];

const OptionallyLinked = ({ url, children }) =>
  url ? <Link className="HomePage-messageLink" to={url}>{children}</Link> : children;

const Home = ({ isMobile }) => {

  const [currentMessage, updateCurrentMessage] = React.useState(-1);

  React.useEffect(() => {
    const delay = currentMessage === -1 ? 100 : currentMessage === messages.length - 1 ? 7000 : 3500;
    const timer = setTimeout(() => updateCurrentMessage((currentMessage + 1) % messages.length), delay);
    return () => clearTimeout(timer);
  }, [currentMessage]);

  return (
    <div className="HomePage">
      {messages.map(({ scribble, blocks, arrow, url }, ix) =>
        <OptionallyLinked key={"msg" + ix} url={url}>
          <div className={classNames({ "HomePage-message": true, "HomePage-message-show": currentMessage === ix })}>
            <span className="ScribbleText">{scribble}</span>
            {blocks.map((block, bix) => <BlockText key={"msg" + ix + "block" + bix}>{block}</BlockText>)}
            {url ? <img src={imgArrow} className="HomePage-arrow" alt=""/> : null}
          </div>
        </OptionallyLinked>
      )}
    </div>
  );
}

export default Home;
