import React from "react";
import * as Reach from "@reach/router"
import classNames from "classnames";

import BlockText from "../../element/BlockText"
import Footer from "../../element/Footer"
import ScrollFader from "../../element/ScrollFader"
import SlideImage from "../../element/SlideImage"

import * as Lib from "../../lib"
import * as API from "../../lib/api"
import * as Hooks from "../../lib/Hooks"

import "./style.scss";
import imgRule from "./scribble-rule.svg";
import imgRuleSmall from "./scribble-rule-small.svg";
import imgScrollBlack from "../../img/scroll-black.svg"
import imgScrollWhite from "../../img/scroll-white.svg"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

// ----------------------------------------------------------------------------

const TextLeft = ({ fields, faderProps }) =>
  <div className="WorkProjectPage-textSplit" style={{color: fields.colour}}>
    <div className="WorkProjectPage-textSplitLeft">
      <ScrollFader {...faderProps} fadePoint={0.8}>
        {Lib.renderParagraphText(fields.text)}
      </ScrollFader>
    </div>
  </div>

const TextRight = ({ fields, faderProps }) =>
  <div className="WorkProjectPage-textSplit" style={{color: fields.colour}}>
    <div className="WorkProjectPage-textSplitRight">
      <ScrollFader {...faderProps} fadePoint={0.8}>
        {Lib.renderParagraphText(fields.text)}
      </ScrollFader>
    </div>
  </div>

const TextSplit = ({ fields, faderProps }) =>
  <div className="WorkProjectPage-textSplit" style={{color: fields.colour}}>
    <div className="WorkProjectPage-textSplitLeft">
      <ScrollFader {...faderProps} fadePoint={faderProps.isMobile ? 0.6 : 0.8 }>
        {Lib.renderParagraphText(fields.leftText)}
      </ScrollFader>
    </div>
    <div className="WorkProjectPage-textSplitRight WorkProjectPage-textSplitRight-larger">
      <ScrollFader {...faderProps} fadePoint={faderProps.isMobile ? 0.6 : 0.7 }>
        {Lib.renderParagraphText(fields.rightText)}
      </ScrollFader>
    </div>
  </div>

// ----------------------------------------------------------------------------

const StatementWithImage = ({ fields, faderProps }) => {
  const image = fields.image.fields;
  const className = classNames({
    "WorkProjectPage-statementWithImage": true,
    "WorkProjectPage-statementWithImage-imageRight": fields.showImageRight,
    "WorkProjectPage-statementWithImage-imageLeft": !fields.showImageRight
  });
  return (
    <div className={className}>
      <div className="WorkProjectPage-statementWithImageContent">
        <div className="WorkProjectPage-statementWithImageStatement">
          <ScrollFader {...faderProps} fadePoint={0.65}>
            {Lib.maybe(fields.subtitle, (subtitle) => <div className="ScribbleText">{subtitle}</div>)}
            <MultiBlockText colour={fields.textColour || Lib.autoColour(fields.colour)} background={fields.colour} variant="WorkProjectPage-blockText">
              {faderProps.isMobile
                  ? fields.statementMobile || fields.statement
                  : fields.statement
              }
            </MultiBlockText>
          </ScrollFader>
        </div>
        <img src={image.file.url} alt={image.description} className="WorkProjectPage-statementWithImageImage"/>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const TextWithImage = ({ fields, faderProps }) => {
  const bounds = Hooks.useBounds(faderProps);
  const image = fields.image.fields;
  const style = fields.colour ? { color: fields.colour } : {};
  const backgroundStyle = fields.backgroundColour ? {
    backgroundColor: fields.backgroundColour,
    opacity: Lib.scrollZoneActive(faderProps.scrollPos, bounds, faderProps.screenArea, 0.4, 0.6) ? 1 : 0
  } : {};
  const className = classNames({
    "WorkProjectPage-textWithImage": true,
    "WorkProjectPage-textWithImage-imageRight": fields.showImageRight,
    "WorkProjectPage-textWithImage-imageLeft": !fields.showImageRight
  });
  return (
    <div className={className} ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      <div className="WorkProjectPage-textWithImageContent">
        <div className="WorkProjectPage-textWithImageText" style={style}>
          <ScrollFader {...faderProps} fadePoint={0.65}>
            {Lib.renderParagraphText(fields.text)}
          </ScrollFader>
        </div>
        <img src={image.file.url} alt={image.description} className="WorkProjectPage-textWithImageImage"/>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const Project = ({ fields, isMobile, screenArea, pageHeight, scrollPos }) => {
  const bounds = Hooks.useBounds({ screenArea, pageHeight });
  const title = fields.title;
  const text = fields.text;
  const image = fields.image.fields;
  const style = { color: fields.colour };
  const backgroundStyle = fields.backgroundColour ? {
    backgroundColor: fields.backgroundColour,
    opacity: Lib.scrollZoneActive(scrollPos, bounds, screenArea, 0.4, 0) ? 1 : 0
  } : {};
  const className = classNames({
    "WorkProjectPage-project": true,
    "WorkProjectPage-project-imageRight": fields.showImageRight,
    "WorkProjectPage-project-imageLeft": !fields.showImageRight
  });
  return (
    <div className={className} style={style} ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      <div className="WorkProjectPage-projectContainer">
        <img src={image.file.url} alt={image.description} className="WorkProjectPage-projectImage"/>
        <div
          className="WorkProjectPage-projectContent">
            {title && <div className="WorkProjectPage-projectTitle">{title.split("\n").map(text => <div key={text}>{text}</div>)}</div>}
            {text && <div className="WorkProjectPage-projectText">{text}</div>}
        </div>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const WipImages = ({ fields, screenArea, pageHeight, scrollPos }) => {
  const bounds = Hooks.useBounds({ screenArea, pageHeight })
  const style = fields.backgroundColour ? {
    color: Lib.autoColour(fields.backgroundColour)
  } : {};
  const backgroundStyle = fields.backgroundColour ? {
    backgroundColor: fields.backgroundColour,
    opacity: Lib.scrollZoneActive(scrollPos, bounds, screenArea, 0.4, 0.8) ? 1 : 0
  } : {};
  return (
    <div className="WorkProjectPage-imageBlock" style={style} ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      <div className="WorkProjectPage-imageBlockContainer">
        {Lib.maybe(fields.caption, text => <div className="WorkProjectPage-wipImagesText">{text}</div>)}
        <ul className="WorkProjectPage-wipImageContainer">
          {fields.images.map(({ fields: img }, ix) =>
              <li key={ix} className="WorkProjectPage-wipImage">
                <div
                  className="WorkProjectPage-wipImageInner"
                  style={{backgroundImage: "url(" + img.file.url + ")"}}/>
              </li>
          )}
        </ul>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const MobileImages = ({ fields, isMobile, screenArea, pageHeight, scrollPos, scaling }) => {
  const [currentImage, updateCurrentImage] = React.useState(0);
  const bounds = Hooks.useBounds({ screenArea, pageHeight })
  const backgroundStyle = {
    backgroundImage: Lib.maybe(fields.backgroundImage, ({ fields: img }) => url(img)),
    opacity: Lib.scrollZoneActive(scrollPos, bounds, screenArea, 0.1, 0.8) ? 1 : 0
  };
  const content = isMobile
    ? <>
      <div className="WorkProjectPage-mobileImagesSwiper">
        <Swiper
          key={scaling}
          slidesPerView="auto"
          slidesOffsetBefore={20 * scaling}
          slidesOffsetAfter={20 * scaling}
          spaceBetween={24 * scaling}
          onSlideChange={e => updateCurrentImage(e.activeIndex)}>
            {fields.images.map(({ fields: img }, ix) =>
              <SwiperSlide key={ix}>
                <div className="WorkProjectPage-mobileImage">
                  <div
                    className="WorkProjectPage-mobileImageInner"
                    style={{backgroundImage: url(img) }}/>
                </div>
              </SwiperSlide>
            )}
        </Swiper>
      </div>
      <ul className="WorkProjectPage-mobileImagesIndicator">
        {fields.images.map((_, ix) =>
          <li key={ix} className={classNames({
            "WorkProjectPage-mobileImagesIndicatorPip": true,
            "WorkProjectPage-mobileImagesIndicatorPip-active": ix === currentImage
          })}/>
        )}
      </ul>
    </>
    : <ul className="WorkProjectPage-mobileImagesWrapper">
        {fields.images.map(({ fields: img }, ix) =>
          <li key={ix} className="WorkProjectPage-mobileImage">
            <div
              className="WorkProjectPage-mobileImageInner"
              style={{backgroundImage: url(img) }}/>
          </li>
        )}
    </ul>;

  return (
    <div className="WorkProjectPage-mobileImages" ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      {content}
    </div>
  );
}

// ----------------------------------------------------------------------------

const DesktopMockups = ({ fields, isMobile, screenArea, pageHeight, scrollPos }) => {
  const bounds = Hooks.useBounds({ screenArea, pageHeight });
  const images = fields.images;
  const style = (fields.textColour || fields.backgroundColour) ? {
    color: fields.textColour || Lib.autoColour(fields.backgroundColour)
  } : {}
  const backgroundStyle = {
    backgroundImage: Lib.maybe(fields.backgroundImage, ({ fields: img }) => url(img)),
    backgroundColor: fields.backgroundColour,
    opacity: Lib.scrollZoneActive(scrollPos, bounds, screenArea, 0.1, 0.8) ? 1 : 0
  };
  return (
    <div className="WorkProjectPage-desktopMockups" ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      <div className="WorkProjectPage-desktopMockupsContainer">
        {Lib.maybe(fields.caption, text => <div className="WorkProjectPage-desktopMockupsText" style={style}>{text}</div>)}
        <ul className="WorkProjectPage-desktopMockupsContent">
          {images.map(image => <li key={image.sys.id}><img src={image.fields.file.url} alt={image.fields.description}/></li>)}
        </ul>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const ServicesList = ({ fields, screenArea, pageHeight, scrollPos, isMobile }) => {
  const bounds = Hooks.useBounds({ screenArea, pageHeight })
  const style = (fields.textColour || fields.backgroundColour) ? {
    color: fields.textColour || Lib.autoColour(fields.backgroundColour)
  } : {};
  const backgroundStyle = fields.backgroundColour ? {
    backgroundColor: fields.backgroundColour,
    opacity: Lib.scrollZoneActive(scrollPos, bounds, screenArea, isMobile ? 0.6 : 0.4, 0) ? 1 : 0
  } : {};
  return (
    <div className="WorkProjectPage-services" style={style} ref={bounds.ref}>
      <div className="WorkPageProject-background WorkPageProject-background-fade" style={backgroundStyle}/>
      <ul className="WorkProjectPage-serviceList">
        <li className="WorkProjectPage-serviceHeader">Services involved in this project:</li>
        {fields.services.split("\n").map(service =>
          <li className="WorkProjectPage-serviceItem" key={service}>{service}</li>
        )}
      </ul>
    </div>
  );
}

// ----------------------------------------------------------------------------

const MultiBlockText = ({ children, ...props }) =>
  children.split("\n").map(text =>
    <BlockText key={text} {...props}>
      {text}
    </BlockText>)

const url = (img) => "url(" + img.file.url + ")"

// ----------------------------------------------------------------------------

const WorkProject = ({ screenArea, contentWidth, pageHeight, scrollPos, scaling, slug, isMobile, onInvertNav }) => {

  const navigate = Reach.useNavigate();

  const [project, updateProject] = React.useState();
  const headerBounds = Hooks.useBounds({ screenArea, pageHeight });
  const scrollBounds = Hooks.useBounds({ screenArea, pageHeight });

  const faderProps = { isMobile, screenArea, pageHeight, scrollPos };

  const loadProject = (project) => {
    updateProject(project);
    onInvertNav(Lib.isDarkColour(project.colour));
  };

  React.useEffect(() => {
    API.getCaseStudy(slug)
      .then(project => {
        if (!project) navigate("/work", { replace: true });
        else loadProject(project);
      })
      .catch(err => console.log(err));
      // eslint-disable-next-line
  }, [slug]);

  if (!project) return <div className="WorkProjectPage"/>;

  const backgroundColour =
    scrollPos < 0 || headerBounds.rect.height === 0 || Lib.scrollZoneActive(scrollPos, headerBounds, screenArea, 0, isMobile ? 0.6 : 0.4)
      ? project.colour
      : "#FFFFFF";

  const preludeStyle = { color: project.headerTextColour || Lib.autoColour(project.colour) };
  const preludeRuleStyle = Lib.isDarkColour(project.colour) ? {} : { filter: "invert(1)" };

  const headerScrollClass = classNames({
    "WorkProjectPage-headerScroll": true,
    "WorkProjectPage-headerScroll-out": scrollBounds.rect.height > 0 && scrollBounds.rect.bottom < (scrollPos + screenArea.height * 0.65)
  });

  return <div className="WorkProjectPage">
    <div className="WorkPageProject-background WorkPageProject-background-swipe" style={{backgroundColor: backgroundColour}}/>

    <div className="WorkProjectPage-headerZone" ref={headerBounds.ref}>
      <div className="WorkProjectPage-header">
        <div className="WorkProjectPage-title">
          <div className="WorkProjectPage-titleInner">
            <MultiBlockText>{project.title}</MultiBlockText>
          </div>
        </div>
        <div className="WorkProjectPage-headerImageContainer">
          <img src={project.image.fields.file.url} className="WorkProjectPage-headerImage" alt=""/>
        </div>
        <div className="WorkProjectPage-headerScrollContainer">
          <img src={Lib.isDarkColour(project.colour) ? imgScrollWhite : imgScrollBlack} className={headerScrollClass} ref={scrollBounds.ref} alt="Scroll down"/>
        </div>
      </div>
      <div className="WorkProjectPage-prelude" style={preludeStyle}>
        <img className="WorkProjectPage-preludeRuleTop" src={imgRuleSmall} style={preludeRuleStyle} alt=""/>
        <div className="WorkProjectPage-preludeText">
          <div className="WorkProjectPage-preludeTextColumn">
            {Lib.renderRichText(project.headerTextLeft)}
          </div>
          <div className="WorkProjectPage-preludeTextColumn">
            {Lib.renderRichText(project.headerTextRight)}
          </div>
        </div>
        <img className="WorkProjectPage-preludeRuleBottom" src={imgRule} style={preludeRuleStyle} alt=""/>
      </div>
    </div>

    {project.sections.filter(section => section.fields).map(section => {
      const type = section.sys.contentType.sys.id;
      const key = section.sys.id;
      const fields = section.fields;
      switch (type) {

        case "textBlockBodyLeftH4Right":
          return <TextSplit key={key} fields={fields} faderProps={faderProps}/>

        case "textBlockBodyLeft":
          return <TextLeft key={key} fields={fields} faderProps={faderProps}/>

        case "textBlockBodyRight":
          return <TextRight key={key} fields={fields} faderProps={faderProps}/>

        case "statementLeftImageRight":
          return <StatementWithImage key={key} fields={fields} faderProps={faderProps}/>

        case "textBlockBodyLeftImageRight":
          return <TextWithImage key={key} fields={fields} faderProps={faderProps}/>

        case "project":
          return <Project key={key} fields={fields} {...faderProps}/>

        case "wipImages":
          return <WipImages key={key} fields={fields} {...faderProps}/>

        case "slideImage":
          return (
            <div className="WorkProjectPage-slideImage" key={key}>
              <SlideImage
                images={extractImages(fields.images)}
                imagesMobile={extractImages(fields.imagesMobile)}
                {...faderProps}
                triggerPoint={isMobile ? 0.9 : 0.7}/>
            </div>
          );

        case "servicesList":
          return <ServicesList key={key} fields={fields} {...faderProps}/>

        case "mobileImages":
          return <MobileImages key={key} fields={fields} {...faderProps} scaling={scaling}/>

        case "desktopMockups":
          return <DesktopMockups key={key} fields={fields} {...faderProps}/>

        default:
          console.log ("???", type)
          return null;
      }
    })}

    <Footer solid/>
  </div>;
}

const extractImages = (imgs) =>
  imgs ? imgs.map(img => ({ url: img.fields.file.url, description: img.fields.description })) : []

export default WorkProject;
