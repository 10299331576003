import React from "react";
import * as Reach from "@reach/router"
import moment from "moment";
import classNames from "classnames";

import Footer from "../../element/Footer"

import * as Lib from "../../lib"
import * as API from "../../lib/api"
import * as Hooks from "../../lib/Hooks"

import "./style.scss"

const backgrounds = [
  { h: 300, s: 8, l: 90, a: 0 },
  { h: 215, s: 64, l: 86, a: 1 },
  { h: 129, s: 68, l: 92, a: 1 }
];

const makeBackgroundColour = (p) => {
  const s = p * (backgrounds.length - 1);
  const ix = Math.max(Math.floor(s), 0);
  const phase = s - ix;
  const def1 = backgrounds[ix];
  const def2 = backgrounds[ix + 1] || def1;
  return {
    h: Lib.lerp(def1.h, def2.h, phase),
    s: Lib.lerp(def1.s, def2.s, phase),
    l: Lib.lerp(def1.l, def2.l, phase),
    a: Lib.lerp(def1.a, def2.a, phase)
  };
};

const renderColour = ({ h, s, l, a }) =>
  "hsla(" + h + ", " + s + "%, " + l + "%, " + a + ")";

const splitImages = (doc) => {
  const images = [];
  const content = doc.content.filter(node => {
    if (node.nodeType === "embedded-asset-block") {
      if (node.data?.target?.fields?.file?.contentType?.startsWith("image/")) {
        images.push(node.data.target.fields);
        return false;
      }
    }
    return true;
  });
  return [images, Lib.modify(doc, { content })];
};

const NewsArticle = ({ screenArea, scrollPos, pageHeight, scaling, isMobile, slug }) => {

  const navigate = Reach.useNavigate();

  const [newsItem, updateNewsItem] = React.useState(API.getCachedNewsItem(slug));
  const [className, updateClassName] = React.useState("NewsArticlePage");
  const bounds = Hooks.useBounds({ screenArea, pageHeight });

  const scrollArea = (pageHeight - screenArea.height);
  const scrollScalar = scrollArea === 0 ? 0 : scrollPos / scrollArea;

  React.useEffect(() => {
    API.getNewsItem(slug)
      .then(news => {
        if (!news) navigate("/news", { replace: true });
        else {
          updateNewsItem(news);
          setTimeout(() => updateClassName("NewsArticlePage NewsArticlePage--fadeIn"), 100);
        }
      })
      .catch(err => console.log(err));
  }, [slug]);

  const backgroundColour = makeBackgroundColour(scrollScalar);

  const imageBottom = bounds.rect.top + scrollPos + 390 * scaling;

  const contentBottom = bounds.rect.bottom === 0
    ? imageBottom
    : bounds.rect.bottom - 80 * scaling;

  const imageStyle =
    imageBottom > contentBottom
      ? "NewsArticlePage-image NewsArticlePage-image-bottom"
      : "NewsArticlePage-image"

  return (
    <div className={className} style={{backgroundColor: renderColour(backgroundColour)}}>
      {Lib.maybe(newsItem, data => {
        const [images, body] = isMobile ? [[], data.content] : splitImages(data.content);
        const imageIndex = Math.min(Math.floor(scrollScalar * images.length), images.length - 1);
        return <>
          <div className="NewsArticlePageContent" ref={bounds.ref}>
            <div className={imageStyle}>
              {images.map((img, ix) =>
                  <img
                    key={"img" + ix}
                    src={API.getCachedImage(img.file.url)}
                    className={classNames({ "visible": ix === imageIndex })}
                    alt={img.description}/>)}
            </div>
            <div className="NewsArticlePage-date">{moment(data.date).format("Do MMMM YYYY")}</div>
            <div className="NewsArticlePage-title">{data.title}</div>
            <div className="NewsArticlePage-text">{Lib.renderRichText(body)}</div>
          </div>
          <Footer/>
        </>;
      })}
    </div>
  );
}

export default NewsArticle;
