import React from "react"
import { Link } from "@reach/router"
import classNames from "classnames"

import BlockText from "../../element/BlockText"
import Footer from "../../element/Footer"
import ScrollFader from "../../element/ScrollFader"

import * as Hooks from "../../lib/Hooks"

import "./style.scss"
import imgEstablished from "./established.jpg"
import imgEstablishedSlider from "./established-overlay.svg"
import imgDesignLed from "./design-led.png"
import imgDesignLedSlider from "./design-is-good.svg"
import imgHeader from "./header.png"
import imgScroll from "../../img/scroll-black.svg"
import imgWorkLink from "./sticker-work-collage.png"
import imgWorkLinkArrow from "./work-link-arrow.svg"

const About = ({ screenArea, pageHeight, scrollPos, isMobile }) => {

  const faderProps = { screenArea, pageHeight, scrollPos };
  const headerBounds = Hooks.useBounds({ screenArea, pageHeight });

  const establishedTrigger = Hooks.useScrollTrigger({
    className: "AboutPage-establishedSlider",
    triggerPoint: isMobile ? 0.7 : 0.6,
    ...faderProps
  });

  const goodAtTrigger = Hooks.useScrollTrigger({
    className: "AboutPage-goodAtList",
    triggerPoint: 0.6,
    ...faderProps
  });

  const designTrigger = Hooks.useScrollTrigger({
    className: "AboutPage-designSlider",
    triggerPoint: 0.7,
    ...faderProps
  });

  const backgroundOpacity =
    headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * 0.65)
      ? 1
      : 0;

  const heroImageClass = classNames({
    "Hero-image": true,
    "Hero-image-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * (isMobile ? 0.8 : 0.7))
  });

  const heroTitleClass = classNames({
    "Hero-title": true,
    "Hero-title-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * (isMobile ? 0.7 : 0.8))
  });

  const heroScrollClass = classNames({
    "Hero-scroll": true,
    "Hero-scroll-out": headerBounds.rect.height > 0 && headerBounds.rect.bottom < (scrollPos + screenArea.height * 0.85)
  });

  return <div className="Page">

    <div className="AboutPage-background" style={{opacity: backgroundOpacity}}/>

    <div className="Hero" ref={headerBounds.ref}>
      <div className="Hero-content">
        <div className={heroImageClass}>
          <img src={imgHeader} className="Hero-img Hero-img-about" alt=""/>
        </div>
        <div className={heroTitleClass}>
          <div className="ScribbleText">Hello, we're magneticNorth</div>
          <BlockText>A DIGITAL</BlockText>
          <BlockText>DESIGN STUDIO</BlockText>
          <div className="ScribbleText">based in Manchester</div>
        </div>
        <img src={imgScroll} className={heroScrollClass} alt="Scroll down"/>
      </div>
    </div>

    <div className="AboutPage">

      <div className="AboutPage-block">
        <div className="AboutPage-statement">
          <ScrollFader {...faderProps} fadePoint={isMobile ? 0.6 : 0.6}>
            For over 20 years we’ve helped organisations around the world get better at what they do, by putting digital at the heart of how they do it.
          </ScrollFader>
        </div>
      </div>

      <div className="AboutPage-establishedBlock">
        <img className="AboutPage-establishedImage" src={imgEstablished} alt=""/>
        <img src={imgEstablishedSlider} alt="Doing digital since 2000" {...establishedTrigger}/>
      </div>

      <div className="AboutPage-block">
        <div className="AboutPage-goodAt">
          We design digital things. That means we’re really good at...
          {isMobile
            ? <GoodAtMobile trigger={goodAtTrigger}/>
            : <GoodAtDesktop trigger={goodAtTrigger}/>
          }
        </div>
      </div>

      <div className="AboutPage-designBlock">
        <div className="AboutPage-designSliderLimiter">
          <img src={imgDesignLedSlider} alt="Design is good" {...designTrigger}/>
        </div>
        <img className="AboutPage-designImage" src={imgDesignLed} alt=""/>
      </div>

      <div className="AboutPage-block">
        <div className="AboutPage-designStatement">
          <ScrollFader {...faderProps} fadePoint={isMobile ? 0.6 : 0.75}>
            <p>We work in a design-led way — user focussed, making-based and totally collaborative.</p>
            <p>We believe that great digital design has the power to help organisations survive and thrive.</p>
          </ScrollFader>
        </div>
        <div className="AboutPage-fluxx">
          <ScrollFader {...faderProps} fadePoint={isMobile ? 0.6 : 0.75}>
            <p>We're now part of a new independently owned innovation powerhouse following our merger with Fluxx, London’s leading innovation consultancy. Together we're able to support clients anywhere in the world with the business transformation opportunities that lie ahead.</p>
            <p><a href="https://fluxx.uk.com">Find out more about Fluxx</a></p>
          </ScrollFader>
        </div>
      </div>

      <div className="AboutPage-workLinkSection">
        <Link to="/work">
          <div className="AboutPage-workLink">
            {isMobile
              ? <WorkLinkMobile/>
              : <WorkLinkDesktop/>
            }
            <img className="AboutPage-workLinkArrow" src={imgWorkLinkArrow} alt=""/>
          </div>
        </Link>
        <img className="AboutPage-workLinkImage" src={imgWorkLink} alt=""/>
      </div>

    </div>

    <Footer solid/>

  </div>;
}

const GoodAtMobile = ({ trigger }) =>
  <ul {...trigger}>
    <li>
      <BlockText variant="AboutPage-blockText">PINNING DOWN</BlockText>
      <BlockText variant="AboutPage-blockText">PROBLEMS</BlockText>
    </li>
    <li><BlockText variant="AboutPage-blockText">COMING UP WITH IDEAS</BlockText></li>
    <li>
      <BlockText variant="AboutPage-blockText AboutPage-blockText-inverse">AND BRINGING THEM</BlockText>
      <BlockText variant="AboutPage-blockText AboutPage-blockText-inverse">TO LIFE</BlockText>
    </li>
  </ul>

const GoodAtDesktop = ({ trigger }) =>
  <ul {...trigger}>
    <li><BlockText variant="AboutPage-blockText">PINNING DOWN PROBLEMS</BlockText></li>
    <li><BlockText variant="AboutPage-blockText">COMING UP WITH IDEAS</BlockText></li>
    <li><BlockText variant="AboutPage-blockText AboutPage-blockText-inverse">AND BRINGING THEM TO LIFE</BlockText></li>
  </ul>

const WorkLinkDesktop = () =>
  <BlockText variant="AboutPage-workLinkText">TAKE A LOOK AT OUR WORK</BlockText>

const WorkLinkMobile = () =>
  <>
  <BlockText variant="AboutPage-workLinkText">TAKE A LOOK</BlockText>
  <BlockText variant="AboutPage-workLinkText">AT OUR WORK</BlockText>
  </>

export default About;
