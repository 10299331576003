import * as ContentfulRichText from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

export const lerp = (start, end, p) => start + (end - start) * p;

export const maybe = (x, f) => x == null ? null : f(x);

export const getRelativePosition = (e1, e2) => {
  const r1 = e1.getBoundingClientRect();
  const r2 = e2.getBoundingClientRect();
  return {
    x: r1.x - r2.x,
    left: r1.left - r2.left,
    y: r1.y - r2.y,
    top: r1.top - r2.top,
    width: r1.width,
    height: r1.height
  };
};

export const getAbsolutePosition = (e1) => {
  const r1 = e1.getBoundingClientRect();
  const r2 = document.documentElement.getBoundingClientRect();
  const x = r1.x - r2.x;
  const y = r1.y - r2.y;
  return {
    x,
    left: x,
    y,
    top: y,
    width: r1.width,
    height: r1.height,
    right: x + r1.width,
    bottom: y + r1.height
  };
};

const luminance = (colour) => {
  const f = c => c <= 0.03928 ? (c / 12.92) : Math.pow((c + 0.055) / 1.055, 2.4)
  const r = f(((colour & 0xFF0000) >> 16) / 255);
  const g = f(((colour & 0xFF00) >> 8) / 255);
  const b = f((colour & 0xFF) / 255);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

const contrastRatio = (c1, c2) => {
  const l1 = luminance(c1);
  const l2 = luminance(c2);
  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

const isAccessibleContrast = (c1, c2) =>
  contrastRatio(c1, c2) >= 4.5;

export const isDarkColour = (c) =>
  isAccessibleContrast(parseInt(c.substring(1), 16), 0) ? false : true;

export const autoColour = (c) =>
  isDarkColour(c) ? "#FFFFFF" : "#000000";

//
// Renders a Contentful rich-text block with embedded images included.
//
export const renderRichText = (doc) =>
  ContentfulRichText.documentToReactComponents(doc, {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node.data.target.fields;
        if (file.contentType.indexOf("image/") === 0) {
          return <img src={file.url} className="embedded-image" alt={title}/>;
        }
      },
    },
  });

export const renderParagraphText = (text) =>
  text.split("\n").map(text => <p key={text}>{text}</p>);

//
// Creates a copy of `src` and assigns all the properties in `dest` to it.
//
export const modify = (src, dest) =>
  Object.assign(Object.assign({}, src), dest);


export const clamp = (min, value, max) =>
  value > max ? max : value < min ? min : value;

export const scrollZoneActive = (scrollPos, bounds, screenArea, top, bot) =>
  bounds.rect
    && bounds.rect.height > 0
    && scrollPos >= (bounds.rect.top - screenArea.height * top)
    && scrollPos <= (bounds.rect.bottom - screenArea.height * bot)
