import "./style.scss"

const BlockText = ({ colour, background, variant, children }) =>
  <div className={variant ? "BlockText " + variant : "BlockText"}>
    <span className="BlockText-main" style={{ color: colour, backgroundColor: background }}>
      <span className="BlockText-offset">
        {children}
      </span>
    </span>
  </div>

export default BlockText;
