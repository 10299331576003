import React from "react"
import * as Lib from "./"

export const useEventListener = (target, event, listener, dependencies) =>
  React.useEffect(() => {
    target.addEventListener(event, listener);
    return () => target.removeEventListener(event, listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies ? dependencies : []);

export const useResizeObserver = (ref, listener, dependencies) =>
  React.useEffect(() => {
    const observer = new ResizeObserver(listener);
    observer.observe(ref.current);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies ? dependencies : []);

export const useScrollTrigger = ({ className, screenArea, pageHeight, scrollPos, triggerPoint }) => {

  const ref = React.useRef();
  const [pos, updatePos] = React.useState(-1);

  const fadePos = screenArea.height * (triggerPoint ? triggerPoint : 0.8);
  const active = pos === -1 ? false : pos - scrollPos < fadePos;

  React.useEffect(() => {
    const rect = Lib.getAbsolutePosition(ref.current);
    updatePos(rect.top + rect.height / 2);
  }, [screenArea, pageHeight]);

  return { ref, className: className + (active ? " " + className + "-active" : "") };
};

const initBounds = { x: 0, y: 0, width: 0, height: 0, left: 0, top: 0, bottom: 0, right: 0 };

export const useBounds = ({ screenArea, pageHeight }) => {
  const ref = React.useRef();
  const [rect, updateRect] = React.useState(initBounds);
  React.useEffect(() => {
    if (!ref.current) return;
    updateRect(Lib.getAbsolutePosition(ref.current));
  }, [screenArea, pageHeight]);
  return { ref, rect };
};

export const useCachedState = (cache) => {
  const [value, update] = React.useState(cache.value);
  return [value, (value) => {
    cache.value = value;
    update(value);
  }];
};
