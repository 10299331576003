import React from "react"
import { Router, Redirect, Link, globalHistory } from "@reach/router"
import classNames from "classnames"

import * as Hooks from "./lib/Hooks"

import Header from "./element/Header"

import About from "./page/About"
import Home from "./page/Home"
import Work from "./page/Work"
import WorkProject from "./page/WorkProject"
import News from "./page/News"
import NewsArticle from "./page/NewsArticle"
import Careers from "./page/Careers"
import { Privacy, Cookies } from "./page/Policy"

const html = document.documentElement;

const getScreenArea = () =>
  ({ width: window.innerWidth, height: window.innerHeight })

const onToggleMenu = (b) => {
  document.documentElement.className = b ? "showingMenu" : "";
  if (b) window.scrollTo(0, 0);
}

const App = () => {

  const [scrollPos, updateScrollPos] = React.useState(0);
  const [pageHeight, updatePageHeight] = React.useState(0);
  const [screenArea, updateScreenArea] = React.useState(getScreenArea());
  const [invertNav, updateInvertNav] = React.useState(false);
  const [showCookieNotice, updateShowCookieNotice] = React.useState(true);

  const isMobile = screenArea.width < 768;
  const scaling = isMobile ? (screenArea.width / 375) : Math.min(1, screenArea.width / 1440);

  Hooks.useEventListener(document, "scroll", () => updateScrollPos(html.scrollTop));
  Hooks.useEventListener(window, "resize", () => updateScreenArea(getScreenArea()));

  React.useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (action === "PUSH") {
        setTimeout(() => window.scrollTo(0, 0), 15);
        if (window.__mnTrackPage) window.__mnTrackPage(location.pathname);
        updateScrollPos(0);
      }
      updateInvertNav(false);
    })
  }, []);

  React.useEffect(() => {
    html.style.fontSize = (scaling * 10) + "px"
  }, [scaling])

  React.useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      updatePageHeight(html.scrollHeight);
    });
    observer.observe(html);
    return () => observer.disconnect();
  });

  React.useEffect(() => {
    if (window.localStorage.getItem("dismissed-cookie-notice") === "true") {
      updateShowCookieNotice(false);
      window.__mnTrackStart();
    }
  }, [updateShowCookieNotice]);

  const rejectCookies = () => {
    updateShowCookieNotice(false);
  };

  const acceptCookies = () => {
    window.localStorage.setItem("dismissed-cookie-notice", "true");
    updateShowCookieNotice(false);
    window.__mnTrackStart();
  }

  const pageWidth = isMobile ? 375 : 1440 * scaling;
  const contentWidth = isMobile ? 334 : 1152 * scaling;

  const pageProps = {
    scrollPos,
    screenArea,
    scaling,
    pageWidth,
    pageHeight,
    pageMargin: (screenArea.width - pageWidth) / 2,
    contentWidth,
    contentMargin: (screenArea.width - contentWidth) / 2,
    isMobile
  };

  return <>
    <Header isMobile={isMobile} invert={invertNav} onToggleMenu={onToggleMenu}/>
    <Router className={classNames({ "App": true, "App--withCookieNotice": showCookieNotice })}>
      <Redirect from="/jobs/*" to="/careers" noThrow/>
      <Redirect from="/case-studies/*" to="/work" noThrow/>
      <About path="/about" {...pageProps}/>
      <Work path="/work" {...pageProps}/>
      <WorkProject path="/work/:slug" {...pageProps} onInvertNav={updateInvertNav}/>
      <News path="/news" {...pageProps}/>
      <NewsArticle path="/news/:slug" {...pageProps}/>
      <Careers path="/careers" {...pageProps}/>
      <Privacy path="/privacy-policy"/>
      <Cookies path="/cookie-policy"/>
      <Home path="/" {...pageProps} default/>
    </Router>
    { showCookieNotice &&
      <div className="CookieNotice">
        <div className="CookieNotice-wrapper">
          <div className="CookieNotice-text">
            We use cookies on this site.<br/> <Link to="/cookie-policy">View our cookie policy</Link> for more information.
          </div>
          <ul className="CookieNotice-buttons">
            <li>
              <button className="CookieNotice-button" onClick={rejectCookies} type="button">
                Reject cookies
              </button>
            </li>
            <li>
              <button className="CookieNotice-button" onClick={acceptCookies} type="button">
                Accept cookies
              </button>
            </li>
          </ul>
        </div>
      </div>
    }
  </>;
}

export default App;
