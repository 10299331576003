import React from "react";
import * as Reach from "@reach/router"
import classNames from "classnames"

import Footer from "../../element/Footer"
import * as Lib from "../../lib"
import * as API from "../../lib/api"

import "./style.scss"

const NewsItem = ({ title, date, shortDescription, slug, image, onClick }) =>
  <li className="NewsItem" onClick={(e) => onClick({ title, image, slug }, e)}>
    <div className="NewsItem-title">{title}</div>
    <div className="NewsItem-description">{shortDescription}</div>
    <div className="NewsItem-cta">Read the full article</div>
    { Lib.maybe(image, img => <img src={img.url} className="NewsItem-image" alt=""/>) }
  </li>

const News = ({ scaling, scrollPos, isMobile }) => {

  const pageRef = React.useRef();

  const navigate = Reach.useNavigate();
  const containerRef = React.useRef();

  const [entries, updateEntries] = React.useState([]);
  const [transitionTarget, updateTransitionTarget] = React.useState(null);
  const [toggleTransition, updateToggleTransition] = React.useState(false);

  React.useEffect(() => {
    API.getNews()
      .then(updateEntries)
      .catch(err => console.log(err));
  }, []);

  const handleItemClick = (data, e) => {
    if (isMobile) {
      navigate("/news/" + data.slug);
      return;
    }
    if (transitionTarget || toggleTransition) return;
    const { x, y } = Lib.getAbsolutePosition(e.currentTarget);
    // Undo the coordinate scaling here as we're going to use rem positioning
    // for the transition target item too - if we used the scaled coordinates
    // the position would be off as scaling will be applied twice. Using rems
    // for the target item position ensures it will stay correct if the window
    // is resized mid-transition.
    updateTransitionTarget({ data, pos: { x: x / scaling, y: y / scaling - scrollPos }, scrollPos });
    API.cacheImage(data.image);
    setTimeout(() => updateToggleTransition(true), 100);
    setTimeout(() => { navigate("/news/" + data.slug); }, 1250);
  };

  const pageStyle = transitionTarget ? { position: "fixed", top: -transitionTarget.scrollPos + "px" } : {};

  return (
    <div
      ref={pageRef}
      className={classNames({ "NewsPage": true, "NewsPage--out": toggleTransition })}
      style={pageStyle}>
        <div className="NewsPage-content" ref={containerRef}>
          <ul className="NewsPage-listing">
            { entries.map(entry => <NewsItem key={entry.slug} onClick={handleItemClick} {...entry}/>) }
          </ul>
          { Lib.maybe(transitionTarget, ({ data: { title, image }, pos }) => {
              const top = toggleTransition ? "16rem" : ((pos.y) / 10) + "rem";
              return (
                <div className="NewsPage-transitionTarget" style={{top, left: (pos.x / 10) + "rem"}}>
                  <div className="NewsItem-title">{title}</div>
                  { Lib.maybe(image, img => <img key={img.url} src={img.url} className="NewsItem-image" alt=""/>) }
                </div>
              );
          })}
        </div>
        { entries.length > 0 && <Footer/> }
    </div>
  );
}

export default News;
