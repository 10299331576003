import React from "react";
import { Link } from "@reach/router"

import ScrollFader from "../../element/ScrollFader"
import Footer from "../../element/Footer"

import * as API from "../../lib/api"

import "./style.scss"

const Work = ({ screenArea, pageHeight, scrollPos }) => {

  const faderProps = { screenArea, pageHeight, scrollPos };

  const [projects, updateProjects] = React.useState([]);

  React.useEffect(() => {
    API.getCaseStudies()
      .then(updateProjects)
      .catch(err => console.log(err));
  }, []);

  return (
    <div className="WorkPage">
      <ul className="WorkPage-projects">
        {projects.map(({ title, slug, colour, image, previewImageCrop }) =>
          <li key={slug} className="WorkPage-projectsItem">
            <ScrollFader {...faderProps}>
              <Link to={slug}>
                <div className="Project" style={{backgroundColor: colour}}>
                  <div className="Project-title" style={{borderBottomColor: colour}}>{title}</div>
                  <div className="Project-imageBox">
                    <div
                      className={"Project-image Project-image-" + previewImageCrop.toLowerCase()}
                      style={{backgroundImage: "url(" + image.fields.file.url + ")"}}/>
                  </div>
                </div>
              </Link>
            </ScrollFader>
          </li>
        )}
      </ul>
      { projects.length > 0 && <Footer/> }
    </div>
  );
}

export default Work;
