import React from "react"

import Fader from "../Fader"
import * as Lib from "../../lib"

const ScrollFader = ({ children, screenArea, pageHeight, scrollPos, fadePoint }) => {

  const ref = React.useRef();
  const [pos, updatePos] = React.useState(-1);

  const fadePos = screenArea.height * (fadePoint ? fadePoint : 0.9);
  const visible = pos === -1 ? false : pos - scrollPos < fadePos;

  React.useEffect(() => {
    updatePos(Lib.getAbsolutePosition(ref.current).top);
  }, [screenArea, pageHeight]);

  return (
    <Fader visible={visible} ref={ref}>{children}</Fader>
  );
}

export default ScrollFader;
