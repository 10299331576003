import React from "react"
import { Link, globalHistory } from "@reach/router"
import classNames from "classnames"

import "./style.scss"
import imgLogo from "./logo.png";
import imgClose from "./close.svg";
import imgMenu from "./menu.svg";
import imgEmail from "./email.svg";

const Logo = () =>
  <Link className="Header-logo" to="/"><img src={imgLogo} alt="mN Logo"/></Link>

const EmailButton = () =>
  <a className="Header-email" href="mailto:hello@thisismn.com">
    <img src={imgEmail} alt="Send us an email"/>
  </a>

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: "NavLink NavLink--active" } : {}

const isActive = ({ isCurrent }) =>
  isCurrent ? { className: "NavLink NavLink--active" } : {}

const NavLink = ({ exact, children, ...props }) => (
  <Link className="NavLink" getProps={exact ? isActive : isPartiallyActive} {...props}>
    <span>{children}</span>
  </Link>
)

const Nav = ({ includeHome }) =>
  <ul className="Nav">
    { includeHome && <li><NavLink to="/" exact>Home</NavLink></li> }
    <li><NavLink to="/about">About</NavLink></li>
    <li><NavLink to="/work">Projects</NavLink></li>
    <li><NavLink to="/news">News</NavLink></li>
    <li><NavLink to="/careers">Careers</NavLink></li>
  </ul>

const HeaderDesktop = ({ invert }) =>
  <div className="Header">
    <div className={classNames({ "Header-content": true, "Header-content-invert": invert })}>
      <Logo/>
      <a className="Header-email" href="mailto:hello@thisismn.com">
        <span className="Header-emailText">Send us an email</span>
      </a>
      <Nav includeHome={false}/>
    </div>
  </div>

const HeaderMobile = ({ invert, onToggleMenu }) => {

  const [hasShown, updateHasShown] = React.useState(false);
  const [showMenu, updateShowMenu] = React.useState(false);

  const toggleMenu = (b) => {
    updateShowMenu(b);
    if (b) updateHasShown(true);
    onToggleMenu(b);
  };

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") toggleMenu(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Header">
      <div className={classNames({ "Header-content": true, "Header-content-invert": invert })}>
        <button className="Header-menuButton" type="button" onClick={() => toggleMenu(!showMenu)}>
          <img src={imgMenu} alt="Menu"/>
        </button>
        <Logo/>
        <EmailButton/>
      </div>
      <div className={classNames({ "Menu": true, "Menu--show": showMenu, "Menu--hide": hasShown && !showMenu })}>
        <div className="Menu-content">
          <button className="Header-closeButton" type="button" onClick={() => toggleMenu(!showMenu)}>
            <img src={imgClose} alt="Menu"/>
          </button>
          <Logo/>
          <Nav includeHome={true}/>
          <EmailButton/>
        </div>
      </div>
    </div>
  );
};

const Header = ({ isMobile, ...props }) =>
  isMobile ? <HeaderMobile {...props}/> : <HeaderDesktop {...props}/>

export default Header;
